import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import { Utxo, Contract, TransactionBuilder, ElectrumNetworkProvider, Network } from 'cashscript';
import { AddressCashStarter, MasterCategoryID } from '../../constants/values'
import CasualLogo from '../../assets/img/casualLogo.png';
import cashStarterPledge from '../../functions/cashstarterPledge';
import cashStarterRefund from '../../functions/cashstarterRefund';
import cashStarterClaim from '../../functions/cashstarterClaim';
import cashStarterStop from '../../functions/cashstarterStop';
import cashStarterCancel from '../../functions/cashstarterCancel';
import ArtifactCashStarter from '../../contracts/FundMeV8_Mainnet/json/CashStarter.json';
import ArtifactCashStarterRefund from '../../contracts/FundMeV8_Mainnet/json/CashStarterRefund.json';
import ArtifactCashStarterStop from '../../contracts/FundMeV8_Mainnet/json/CashStarterStop.json';
import ArtifactCashStarterClaim from '../../contracts/FundMeV8_Mainnet/json/CashStarterClaim.json';
import ArtifactCashStarterCancel from '../../contracts/FundMeV8_Mainnet/json/CashStarterCancel.json';
import ImageSpinner from '../../assets/img/BCH2.gif';
import BCHLogo from '../../assets/img/bch.png';
import { stringify } from '@bitauth/libauth';
import axios, { AxiosError } from 'axios';
import ErrorNotification from '../../components/ErrorNotification';
import PledgeModal from '../../components/PledgeModal';
import Spinner from '../../assets/img/spinner.gif';

interface Pledge {
  campaignID: string;
  pledgeID: string;
  name: string;
  message: string;
  amount: number;
}
interface CampaignUtxo extends Utxo {
  name: string;
  owner: string;
  description: string;
  logo: string;
  banner: string;
  pledges: Pledge[];
}
interface SignMessageParams {
  message: string;
  userPrompt?: string;
}
interface SignTransactionParams {
  tx: string;
}
type NetworkType = "mainnet" | "testnet4" | "chipnet";

const CampaignDetail: React.FC = () => {
  const { id } = useParams(); // Get the campaign ID from the URL
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  const [campaignUTXO, setCampaignUTXO] = useState<Utxo>();
  const [campaignMap, setCampaignMap] = useState<Map<number, CampaignUtxo | null>>(new Map());
  const [contractsOK, setContractsOK] = useState(false);
  const [contractCashStarter, setContractCashStarter] = useState<Contract>();
  const [contractCashStarterRefund, setContractCashStarterRefund] = useState<Contract>();
  const [contractCashStarterStop, setContractCashStarterStop] = useState<Contract>();
  const [contractCashStarterClaim, setContractCashStarterClaim] = useState<Contract>();
  const [contractCashStarterCancel, setContractCashStarterCancel] = useState<Contract>();
  const [contractManager, setContractManager] = useState<Contract>();
  const [contractFailMinter, setContractFailMinter] = useState<Contract>();
  const [stringPledgeAmount, setStringPledgeAmount] = useState<string>("");
  const [selectedNFT, setSelectedNFT] = useState<Utxo | null>();
  const [nfts, setNFTs] = useState<Utxo[]>([]);
  const [isExpired, setIsExpired] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Overview');
  const [transactionBuilder, setTransactionBuilder] = useState<TransactionBuilder>();
  const [endDate, setEndDate] = useState('');
  const [endBlock, setEndBlock] = useState(0);
  const [campaignInfo, setCampaignInfo] = useState<CampaignUtxo>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pledgePending, setPledgePending] = useState(false);
  const [refundPending, setRefundPending] = useState(false);
  const [stopPending, setStopPending] = useState(false);
  const [cancelPending, setCancelPending] = useState(false);
  const [claimPending, setClaimPending] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pledgeDetails, setPledgeDetails] = useState({ name: '', message: '' });
  const [pledgeTotal, setPledgeTotal] = useState<number>(0);

  //////////Compile an artifact into a CashScript Contract object
  async function compileContract(contractArtifact: any, args: any[]) {
    const contract = new Contract(contractArtifact, args, {provider: electrumServer, addressType: 'p2sh32'});
    return contract;
  }

  const hexToDecimal = (hex: string): number => {
    // Convert hex to big-endian and then to decimal
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };
  //Function to convert string to bigint
  function convertStringToBigInt(string: string): bigint {
    const bchAmount = parseFloat(string);                 //Parse the BCH string to a floating-point number
    const satoshis = Math.round(bchAmount * 100_000_000); //Convert BCH to satoshis and round the result to avoid precision issues
    return BigInt(satoshis);                              //Convert the satoshis to bigint for precise integer arithmetic
  }
  function toLittleEndianHex(value: any, byteCount: number) {
    let hex = (typeof value === 'bigint' ? value.toString(16) : Number(value).toString(16)); //Check number vs bigint and convert to hex accordingly
    hex = hex.padStart(byteCount * 2, '0'); // Pad with zeros to ensure correct byteCount
    return hex.match(/../g)?.reverse().join('') ?? '';  //Split into chunks of 2 (bytes), reverse (for little endian), and join back
  }
  const handleSelectTab = (tabName: string) => {
    setSelectedTab(tabName);
  };

  async function formatTime(blocks: number): Promise<string> {
    const blockHeight = await electrumServer.getBlockHeight();
    const blocksRemaining = Math.max(blocks - blockHeight, 0);

    if (blocksRemaining == 0) {
      return 'Expired';
    }

    const totalMinutes = blocksRemaining * 10;
    const totalHours = totalMinutes / 60;
    const remainingHours = totalHours % 24;
    const days = Math.floor(totalHours / 24);
    const hours = Math.floor(totalHours % 24);
    const minutes = Math.floor((remainingHours - hours) * 60);

    let endDate = '';
    if (days > 0) {
      endDate += `${days}d `;
    }
    if (hours > 0 || days > 0) { // Include hours if there are any days
      endDate += `${hours}h `;
    }
    endDate += `${minutes}m`;

    return endDate;
  }

  const formatSatoshiToBCH = (satoshis: bigint) => {
    const bch = Number(satoshis) / 100000000;
    return bch.toFixed(8);
  };
  
  async function signMessage({ message, userPrompt }: SignMessageParams): Promise<string | undefined> {
    const options = {
        message: message,
        userPrompt: userPrompt
      };

    console.log('signing message...');
    try {
        if (walletConnectInstance) {
        //const params = JSON.parse(stringify(options));
        
        console.log('wc params:');
        console.log(options);
        const result = await walletConnectInstance.request({
            chainId: connectedChain,
            topic: walletConnectSession.topic,
            request: {
                method: "bch_signMessage",
                params: options,
            },
        });
        console.log('signMessage result: ');
        console.log(result);
        return result;
        }
    } catch (error) {
        console.log('signMessage error: ' + error);
        return undefined;
    }
}

async function signTransaction(options: any) {
  console.log('signing transaction...');
  try {
      if (walletConnectInstance) {
      const params = JSON.parse(stringify(options));
      console.log('wc params:');
      console.log(params);
      const result = await walletConnectInstance.request({
          chainId: connectedChain,
          topic: walletConnectSession.topic,
          request: {
          method: "bch_signTransaction",
          params: params,
          },
      });
      return result;
      }
  } catch (error) {
      console.log('signTransation error: ' + error);
      return undefined;
  }
}

  // Function to take users pledge string, verify only one decimal, remove all but numbers, set state
  const handleSetPledgeAmount = (value: string) => {
    let newValue = value.replace(/[^0-9.]+/g, "").replace(/(\..*)\./g, '$1'); // Allow numbers and a single decimal point

    // Check if there's a decimal point, and if so, limit the length after it to 8
    const decimalIndex = newValue.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = newValue.substring(0, decimalIndex);
      const decimalPart = newValue.substring(decimalIndex + 1, decimalIndex + 9); // Grab up to 8 decimal places
      newValue = `${integerPart}.${decimalPart}`;
    }

    setStringPledgeAmount(newValue !== "" ? newValue : "");
  }

  const handlePledgeModal = () => {
    setIsModalOpen(true);
  };

// Function to forward users pledge to the contract function
const handlePledge = async (name: string, message: string) => {
  setPledgePending(true);

  if (electrumServer && usersAddress && campaignUTXO) {
    const campaignID = campaignUTXO.token?.nft?.commitment.substring(70, 80) ?? "0";
    const pledgeID = campaignUTXO.token?.nft?.commitment.substring(62, 70) ?? "0";;
    const pledgeAmount = convertStringToBigInt(stringPledgeAmount);
    console.log('Pledge details:', { campaignID, pledgeID, name, message, pledgeAmount });

    if (name == '') {
      name = 'Anonymous';
    }
    if (message == '') {
      message = ' ';
    }

    const signResult = await cashStarterPledge({electrumServer, usersAddress, contractCashStarter, campaignID, pledgeID, pledgeAmount, signTransaction, setError });

    if (signResult != undefined) {
      const rawTx = signResult.signedTransaction;
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      // Ask user for confirmation before broadcasting
      //const userConfirmed = window.confirm("Do you want to proceed with broadcasting the transaction?");
      //if (!userConfirmed) {
      //  console.log('Campaign creation cancelled');
      //  setError(`Campaign creation cancelled`);
      //  return;
      //}

      try {
        const result = await electrumServer.sendRawTransaction(rawTx);
        console.log('Broadcasted, txid: ' + result);

        const decimalCampaignID = hexToDecimal(campaignID);
        const decimalPledgeID = hexToDecimal(pledgeID) + 1; //pledgeID is the current pledge# on the campaignUTXO, new pledge adds 1 to it during pledging

        // Save to external server
        //await axios.post('http://localhost:3002/save-pledge', { campaignID: decimalCampaignID, pledgeID: decimalPledgeID, name, message, amount: stringPledgeAmount });  //local dev
        await axios.post('https://fundme.cash/save-pledge', { campaignID: decimalCampaignID, pledgeID: decimalPledgeID, name, message, amount: stringPledgeAmount });
        setError(`Pledge sent! TxID:\n${result}`);
        console.log('Pledge sent! TxID: ', result);
      } catch (error) {
        console.error('Error pledging:', error);
        setError(`Error pledging: \n${error}`);
      }
    }

  } else {
    console.log('Error pledging. Is your wallet connected?');
    setError(`Error pledging. Is your wallet connected?`);
  }

  setPledgePending(false);
}
// Function to forward users pledge to the contract function
const handleClaim = async (campaignID: string) => {
  console.log('handleClaim camapignID: ', campaignID);
  setClaimPending(true);

  if (electrumServer && campaignID && usersAddress != '') {
    const signResult = await cashStarterClaim({electrumServer, usersAddress, contractCashStarter, contractCashStarterClaim, campaignID, signTransaction, setError});
    if (signResult != undefined) {
      const rawTx = signResult.signedTransaction;
      //const rawTx = signResult;
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      electrumServer.sendRawTransaction(rawTx).then((result: string) => {
        console.log('Broadcasted, txid: ' + result);
        setError(`Claimed! TxID:\n${result}`);
      }).catch((error: Error) => {
        setError(`Error claiming:\n${error}`);
        console.error('Error claiming:', error);
      });
    }
  } else {
    console.log('Error claiming. Is the correct wallet connected?');
    setError(`Error claiming. Is the correct wallet connected?`);
  }

  setClaimPending(false);
}

// Function to cancel 
const handleCancel = async (campaignID: string) => {
  console.log('handleClaim camapignID: ', campaignID);
  setCancelPending(true);

  if (electrumServer && campaignID && usersAddress != '') {
    const signResult = await cashStarterCancel({electrumServer, usersAddress, contractCashStarter, contractCashStarterCancel, campaignID, signTransaction, setError});
    if (signResult != undefined) {
      const rawTx = signResult.signedTransaction;
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      // Ask user for confirmation before broadcasting
      if (window.confirm("Are you absolutely sure you want to cancel?")) {
        // User clicked OK
        electrumServer.sendRawTransaction(rawTx).then((result: string) => {
          console.log('Broadcasted, txid: ' + result);
          setError(`Campaign canceled. TxID:\n${result}`);
        }).catch((error: Error) => {
          console.error('Error canceling:', error);
          setError(`Error canceling:\n${error}`);
        });
      } else {
        // User clicked Cancel
        setError('Cancel not submitted');
        console.log('Cancel not submitted');
      }
    }
  } else {
    console.log('Error. Is your wallet connected?');
    setError(`Error. Is your wallet connected?`);
  }

  setCancelPending(false);
}

// Function to forward users pledge to the contract function
const handleRefund = async (campaignID: string, selectedNFT: Utxo) => {
  console.log('handleRefund camapignID: ', campaignID);
  setRefundPending(true);

  if (electrumServer && campaignID && selectedNFT) {
    const campaignID = selectedNFT.token?.nft?.commitment.substring(70, 80) ?? "0";
    const pledgeID = selectedNFT.token?.nft?.commitment.substring(62, 70) ?? "0";

    const signResult = await cashStarterRefund({electrumServer, usersAddress, contractCashStarter, contractCashStarterRefund, campaignID, selectedNFT, signTransaction, setError});
    if (signResult != undefined) {
      const rawTx = signResult.signedTransaction;
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      try {
        const result = await electrumServer.sendRawTransaction(rawTx);
        console.log('Broadcasted, txid: ' + result);

        const decimalCampaignID = hexToDecimal(campaignID);
        const decimalPledgeID = hexToDecimal(pledgeID);

        // Send pledge deletion notice to external server
        //await axios.delete('http://localhost:3002/delete-pledge', { data: { campaignID: decimalCampaignID, pledgeID: decimalPledgeID, txid: result } });  //local dev
        await axios.delete('https://fundme.cash/delete-pledge', { data: { campaignID: decimalCampaignID, pledgeID: decimalPledgeID, txid: result } });
        setError(`Refund submitted. TxID:\n${result}`);
      } catch (error) {
        console.error('Error refunding:', error);
        setError(`Error refunding: \n${error}`);
      }
    }

  } else {
    console.log('Error refunding. Did you select the correct PledgeNFT?');
    setError(`Error refunding. Is your wallet connected?`);
  }

  setRefundPending(false);

}

// Function to forward users pledge to the contract function
const handleStop = async (campaignID: string) => {
  console.log('handleStop camapignID: ', campaignID);
  setStopPending(true);

  if (electrumServer && campaignID && usersAddress) {

    const signResult = await cashStarterStop({electrumServer, contractCashStarter, contractCashStarterStop, campaignID, usersAddress });
    if (signResult != undefined) {
      //const rawTx = signResult.signedTransaction; //using walletConnect
      const rawTx = signResult; //anyone can spend
      console.log('signedTransaction from walletconnect: ');
      console.log(signResult);

      electrumServer.sendRawTransaction(rawTx).then((result: string) => {
          console.log('Broadcasted, txid: ' + result);
          setError(`Campaign stopped. TxID:\n${result}`);
      }).catch((error: Error) => {
          console.error('Error stopping campaign:', error);
          setError(`Error stopping campaign: \n${error}`);
      });

    }
  } else {
    console.log('Error stopping campaign. Is your wallet connected?');
    setError(`Error stopping campaign. Is your wallet connected?`);
  }

  setStopPending(false);
}

  useEffect(() => {
    //console.log('1. useEffect called');
    async function checkAndCompileContracts() {
      //console.log('2. compiling contracts...');
      if (contractsOK === false && electrumServer) {
        try {
          const compiledCashStarter = await compileContract(ArtifactCashStarter, []);
          setContractCashStarter(compiledCashStarter);
          //const compiledFailMinter = await compileContract(ArtifactFailMinter, []);
          const compiledCashStarterRefund = await compileContract(ArtifactCashStarterRefund, []);
          setContractCashStarterRefund(compiledCashStarterRefund);
          const compiledCashStarterStop = await compileContract(ArtifactCashStarterStop, []);
          setContractCashStarterStop(compiledCashStarterStop);
          const compiledCashStarterClaim = await compileContract(ArtifactCashStarterClaim, []);
          setContractCashStarterClaim(compiledCashStarterClaim);
          const compiledCashStarterCancel = await compileContract(ArtifactCashStarterCancel, []);
          setContractCashStarterCancel(compiledCashStarterCancel);

          //setContractFailMinter(compiledFailMinter);
          setContractsOK(true);
          console.log('3. contracts OK');
        } catch (error) {
          console.log('contract compiling error: ' + error);
        }
      } else {
        console.log('electrumServer is not ready yet');
      }
    }
  
    checkAndCompileContracts();

    if (!transactionBuilder) {
      const provider = new ElectrumNetworkProvider(Network.MAINNET);
      //const provider = new ElectrumNetworkProvider(Network.CHIPNET);
      setTransactionBuilder(new TransactionBuilder({ provider }));
    }

  }, [electrumServer]);

  useEffect(() => {
    async function getCampaign() {
      if (!electrumServer) return;
      console.log('electrumServer detected');

      setIsLoading(true);  //starts loading spinner graphic

      //fetch campaign metadata from server       
      let response;
      try {   
        //response = await axios.get(`http://localhost:3002/get-campaign/${id}`); //local dev
        response = await axios.get(`https://fundme.cash/get-campaign/${id}`);
        const campaignInfo = response.data;
        setCampaignInfo(campaignInfo);
        const pledgeTotal = campaignInfo.pledges.reduce((sum: number, pledge: any) => sum + Number(pledge.amount), 0);
        setPledgeTotal(pledgeTotal);
        
      } catch (err) {
        const error = err as AxiosError;
        if (error.response && error.response.status === 404) {
          setFetchError(true);
        } else {
          setFetchError(true);
          console.log('fetch unknown error: ', err);
        }
        setIsLoading(false);
        return;
      }


      //delay to allow electrum to stabilize
      setTimeout(async () => {
        const cashStarterUTXOs: Utxo[] = await electrumServer.getUtxos(AddressCashStarter);
        let campaignUTXO = cashStarterUTXOs.find( 
          utxo => utxo.token?.category == MasterCategoryID //only CashStarter NFT's
            && utxo.token?.nft?.capability == 'minting' //only minting ones
            && utxo.token.nft?.commitment.substring(70, 80) != 'ffffffffff' //not the masterNFT
            && utxo.token.nft?.commitment.substring(70, 80) == toLittleEndianHex(id, 5) //this campaign id
        );

        if (!campaignUTXO) {  //no utxo found, could be failed already
          campaignUTXO = cashStarterUTXOs.find( 
            utxo => utxo.token?.category == MasterCategoryID  //only CashStarter NFT's
              && utxo.token?.nft?.capability == 'mutable'        //only fail()'d campaigns
              && utxo.token.nft?.commitment.substring(70, 80) != 'ffffffffff' //not the masterNFT
              && utxo.token.nft?.commitment.substring(70, 80) == toLittleEndianHex(id, 5) //this specific id
          );
        }
        setCampaignUTXO(campaignUTXO);  //save found campaign

        if (campaignUTXO) { //if an active or fail'd campaign was found
          const tempCampaignMap = new Map<number, CampaignUtxo>(); //Temporary map to populate with CampaignUtxo entries
          const capability = campaignUTXO.token?.nft?.capability;
          const campaignId = hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0");
          const endBlock = hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(52, 60) ?? "0");
          setEndBlock(endBlock);
          const endDate = await formatTime(endBlock);

          //if campaign has already been fail()'d, disables Fail button interaction
          if (capability == 'mutable') {
            setIsFailed(true);
            setIsExpired(true);
            setEndDate(endBlock.toString());

          //if campaign is still active, or not yet fail'd
          } else if (capability == 'minting') {
            const campaignId = hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0");
            const endBlock = hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(52, 60) ?? "0");
            const blockHeight = await electrumServer.getBlockHeight();

            //set whether campaign has passed its expiry block, enables Fail button
            if (blockHeight >= endBlock) {
              setIsExpired(true);
            }
            
              //set map data with block it will be endable at
              {/*
              tempCampaignMap.set(campaignId, {
                ...campaignUTXO,
                name: campaignInfo.name,
                owner: campaignInfo.owner,
                description: campaignInfo.description,
                banner: campaignInfo.banner,
                logo: campaignInfo.logo,
                endDate: endDate
              });
            */}
            setEndDate(endDate);
            //setCampaignMap(tempCampaignMap);
          }

        //else no UTXO was found but its campaignInfo was, campaign was claimed
        } else if (!campaignUTXO && campaignInfo) {
          setIsClaimed(true);
        }

        setIsLoading(false);
      }, 2000); // SetTimeout delay of 2 seconds
    }

    getCampaign();
  }, [electrumServer, id]);

// Get refund NFT's for listing so user can select one
async function fetchReceiptNFTs() {
  if (electrumServer && usersAddress != '') {
    try {
      const utxos: Utxo[] = await electrumServer.getUtxos(usersAddress);

      const filteredNFTs: Utxo[] = utxos.filter(utxo => 
        utxo.token?.category == MasterCategoryID
        && utxo.token?.nft?.commitment.substring(70,80) === toLittleEndianHex(id, 5) // Filter to only this campaign ID
        && utxo.token?.nft?.capability == 'none')     // Filter to only receiptNFTs
      setNFTs(filteredNFTs);
      console.log('set NFTs: ', filteredNFTs);
    } catch (error) {
      console.error('Error fetching UTXOs:', error);
    }
  }
};

//////////////////////////////////////////////////
////////// Return PledgeNFT UTXO
//////////////////////////////////////////////////
const NFTItem: React.FC<{ utxo: Utxo }> = ({ utxo }) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);

  function handleSetSelectedNFT() {
    setIsSelected(current => !current);
    setSelectedNFT(utxo)
  }
  return (
    <StyledNFT onClick={handleSetSelectedNFT} isSelected={isSelected}>
      <StyledNFTTitle>
        Pledge #{hexToDecimal(utxo.token?.nft?.commitment.substring(62, 70) ?? "0")}
      </StyledNFTTitle>
      <StyledSats>
        <StyledPledgeLogo size={20}/> {formatSatoshiToBCH(BigInt(hexToDecimal(utxo.token?.nft?.commitment.substring(0, 11) ?? "0")))}
      </StyledSats>
    </StyledNFT>
  );
};

  return (
    <PageContainer>
      <StyledBackground />

      <ErrorNotification message={error} setError={setError} />

      <PledgeModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onSubmit={handlePledge}
        pledgeAmount={stringPledgeAmount}
      />

    {/*  {[...campaignMap.values()].map((campaign, index) => (
        campaign && (
        */}

        {/* Campaign has a UTXO and campaign data exists on server */}
        {campaignUTXO && campaignInfo && 
            <CampaignDetailContainer>

              <Column>
                <BannerImage logoUrl={campaignInfo.banner} />
                <BannerRow>
                  <UserContainer>
                    {isLoading == true &&
                      <StyledSpinner />
                    }
                    <LogoImage logoUrl={campaignInfo.logo} />
                    <UserName>{campaignInfo.owner}</UserName>
                  </UserContainer>
                </BannerRow>
              </Column>

              <StyledTitleDiv>{campaignInfo.name}</StyledTitleDiv>
              <ProgressBar>
                <Progress progress={(Number(campaignUTXO.satoshis) / hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100} />
                <ProgressBarText>{((Number(campaignUTXO.satoshis) / hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100).toFixed(2)}%</ProgressBarText>
                <ProgressBarSubText>{(Number(campaignUTXO.satoshis) / 100000000 )} / {(hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0") / 100000000)}</ProgressBarSubText>
                {!isFailed && !isClaimed && isExpired && <StyledFailButton width={0} onClick={() => handleStop(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")}>Stop</StyledFailButton>}
              </ProgressBar>

              <StyledPledgeBar>
                <StyledRowDetails>
                  <StyledBlack />
                  <StyledCampaignID><b>Campaign:</b>&nbsp;#{hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")}</StyledCampaignID>
                  {campaignUTXO.token?.nft?.capability == 'mutable' ? 
                    <StyledEnd pledgedAmount={0}>Campaign Stopped</StyledEnd>
                    :
                    <StyledEnd pledgedAmount={1}>
                      <b>Ends:</b>&nbsp;{endDate}
                    </StyledEnd>
                  }

                  <StyledEndBlock><b>End Block:</b>&nbsp;{endBlock}</StyledEndBlock>
                </StyledRowDetails>

                <StyledRowPledge>
                  <StyledBlack />
                  <StyledTitle>Make a Pledge</StyledTitle>
                  <StyledSpacer height={10} />
                  <Column>
                    <StyledPledgeLogo size={40}/>
                    <StyledPledgeInput
                      type="text"
                      placeholder="0.0001"
                      value={stringPledgeAmount}
                      onChange={(e) => handleSetPledgeAmount(e.target.value)}
                    />
                  </Column>
                  <MenuButton disabled={isFailed || isClaimed} width={150} onClick={() => handlePledgeModal()}>
                    {pledgePending ? (
                      <StyledButtonSpinner />
                    ) : (
                      'Pledge'
                    )}  
                  </MenuButton>
                </StyledRowPledge>

                <StyledRowNFTs>
                  <StyledBlack />
                  <StyledTitle>Cancel your Pledge</StyledTitle>
                  <StyledRefundButtons>
                    <StyledRefreshButton onClick={fetchReceiptNFTs} width={0}>Refresh</StyledRefreshButton>
                    <MenuButton disabled={!selectedNFT} width={90} onClick={() => handleRefund(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0", selectedNFT!)}>
                      {refundPending ? (
                        <StyledButtonSpinner />
                      ) : (
                        'Refund'
                      )}
                    </MenuButton>
                  </StyledRefundButtons>
                    
                  <StyledNFTContainer>
                    {nfts.map((utxo, index) => (
                      <NFTItem key={index} utxo={utxo} />
                    ))}
                  </StyledNFTContainer>

                </StyledRowNFTs>
              </StyledPledgeBar>

              <StyledNavBar>
                <StyledNavBarEntry isSelected={selectedTab === 'Overview'} onClick={() => handleSelectTab('Overview')}>Overview</StyledNavBarEntry>
                <StyledNavBarEntry isSelected={selectedTab === 'Updates'} onClick={() => handleSelectTab('Updates')}>Updates</StyledNavBarEntry>
                <StyledNavBarEntry isSelected={selectedTab === 'Claim'} onClick={() => handleSelectTab('Claim')}>Claim</StyledNavBarEntry>
              </StyledNavBar>

              <DescriptionAndLinksContainer>
                <StyledDescriptionTabs>
                  {selectedTab === 'Overview' && (
                    <StyledDescription dangerouslySetInnerHTML={{ __html: campaignInfo.description }} />
                  )}
                  {selectedTab === 'Updates' && (
                    <StyledDescription>
                      <StyledDescriptionCenter>
                        Updates not yet implemented. Campaign creators will be able to post new details here in the future.
                      </StyledDescriptionCenter>
                    </StyledDescription>
                  )}
                  {selectedTab === 'Claim' && (
                    <StyledDescription>
                      <StyledDescriptionCenter>
                        <MenuButton disabled={(Number(campaignUTXO.satoshis)) < (hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0"))} width={150} onClick={() => handleClaim(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")}>
                          {claimPending ? (
                            <StyledButtonSpinner />
                          ) : (
                            'Claim'
                          )}  
                        </MenuButton>
                        Only the address that created the campaign can Claim the campaign. <br /> 
                        The creator can claim the raised funds at any time when they are 100% or above the initial funding target.
                        <p/>
                        <CancelButton disabled={campaignUTXO.token?.nft?.capability != 'minting'} onClick={() => handleCancel(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")}>
                        {cancelPending ? (
                          <StyledButtonSpinner />
                        ) : (
                          'Cancel'
                        )}  
                        </CancelButton>
                        Cancel an active campaign, preventing new pledges.<br/>
                        Only the address that created the campaign can Cancel the campaign.
                      </StyledDescriptionCenter>
                    </StyledDescription>
                  )}
                </StyledDescriptionTabs>

                <StyledPledges>
                  <StyledBlack />
                  <StyledTitle>Pledges</StyledTitle>
                  {!campaignInfo && <StyledButtonSpinner />}
                  <StyledSpacer height={20} />

                  {campaignInfo.pledges.map((pledge, index) => (
                    <StyledDonation key={index}>
                      <StyledDonateName nameLength={pledge.name.length}>
                        {pledge.name}
                      </StyledDonateName>
                      <StyledDonateMessage><i>"{pledge.message}"</i></StyledDonateMessage>
                      <StyledDonateAmount>
                        <StyledPledgeLogo size={20}/>
                        {pledge.amount}
                      </StyledDonateAmount>
                      <StyledPledgeID>#{pledge.pledgeID}</StyledPledgeID>
                    </StyledDonation>
                  ))}
                </StyledPledges>

              </DescriptionAndLinksContainer>
            </CampaignDetailContainer>
          }

          {/* Campaign does not have a UTXO but campaign data exists on server */}
          {!campaignUTXO && campaignInfo && 
            <CampaignDetailContainer>

              <Column>
                <BannerImage logoUrl={campaignInfo.banner} />
                <BannerRow>
                  <UserContainer>
                    {isLoading == true &&
                      <StyledSpinner />
                    }
                    <LogoImage logoUrl={campaignInfo.logo} />
                    <UserName>{campaignInfo.owner}</UserName>
                  </UserContainer>
                </BannerRow>
              </Column>

              <StyledTitleDiv>{campaignInfo.name}</StyledTitleDiv>
              <ProgressBar>
                <ProgressBarText>{pledgeTotal}</ProgressBarText>

                <Progress progress={100} />
              {/*
                <ProgressBarSubText>{(Number(campaignUTXO.satoshis) / 100000000 )} / {(hexToDecimal(campaignUTXO.token?.nft?.commitment.substring(0, 12) ?? "0") / 100000000)}</ProgressBarSubText> 
                {!isFailed && !isClaimed && !isExpired && <StyledFailButton onClick={() => handleFail(campaignUTXO.token?.nft?.commitment.substring(70,80) ?? "0")}>Stop</StyledFailButton>}
              */}
              </ProgressBar>

              <StyledPledgeBar>
                <StyledRowDetails>
                  <StyledBlack />
                  <StyledCampaignID><b>Campaign:</b>&nbsp;#{id}</StyledCampaignID>
                  {!isLoading &&
                    <>
                      <StyledEnd pledgedAmount={pledgeTotal}>{campaignInfo.pledges.length > 0 && !isFailed ? 'Campaign Successful' : 'Campaign Failed'}</StyledEnd>
                      {endBlock != 0 && 
                        <StyledEndBlock><b>End Block:</b>&nbsp;{endBlock}</StyledEndBlock>
                      }
                    </>
                  }
                </StyledRowDetails>

                <StyledRowPledge>
                  <StyledBlack />
                  <StyledTitle>Make a Pledge</StyledTitle>
                  <StyledSpacer height={15} />
                  <Column>
                    <StyledPledgeLogo size={40}/>
                    <StyledPledgeInput
                      type="text"
                      placeholder="Amount"
                      value={stringPledgeAmount}
                      onChange={(e) => handleSetPledgeAmount(e.target.value)}
                    />
                  </Column>
                  <MenuButton disabled={true} width={150}>Pledge</MenuButton>
                </StyledRowPledge>

                <StyledRowNFTs>
                  <StyledBlack />
                  <StyledTitle>Cancel your Pledge</StyledTitle>
                  <StyledRefundButtons>
                    <StyledRefreshButton onClick={fetchReceiptNFTs} width={0}>Refresh</StyledRefreshButton>
                    <MenuButton disabled={!selectedNFT} width={90} onClick={() => handleRefund(toLittleEndianHex(id, 5), selectedNFT!)}>
                      {refundPending ? (
                        <StyledButtonSpinner />
                      ) : (
                        'Refund'
                      )}
                    </MenuButton>
                  </StyledRefundButtons>
                    
                  <StyledNFTContainer>
                    {nfts.map((utxo, index) => (
                      <NFTItem key={index} utxo={utxo} />
                    ))}
                  </StyledNFTContainer>

                </StyledRowNFTs>
              </StyledPledgeBar>

              <StyledNavBar>
                <StyledNavBarEntry isSelected={selectedTab === 'Overview'} onClick={() => handleSelectTab('Overview')}>Overview</StyledNavBarEntry>
                <StyledNavBarEntry isSelected={selectedTab === 'Updates'} onClick={() => handleSelectTab('Updates')}>Updates</StyledNavBarEntry>
                <StyledNavBarEntry isSelected={selectedTab === 'Claim'} onClick={() => handleSelectTab('Claim')}>Claim</StyledNavBarEntry>
              </StyledNavBar>

              <DescriptionAndLinksContainer>
                <StyledDescriptionTabs>
                  {selectedTab === 'Overview' && (
                    <StyledDescription dangerouslySetInnerHTML={{ __html: campaignInfo.description }} />
                  )}
                  {selectedTab === 'Updates' && (
                    <StyledDescription>
                      <StyledDescriptionCenter>
                        Updates not yet implemented. Campaign creators will be able to post new details here in the future.
                      </StyledDescriptionCenter>
                    </StyledDescription>
                  )}
                  {selectedTab === 'Claim' && (
                    <StyledDescription>
                      <StyledDescriptionCenter>
                        <MenuButton disabled={true} width={150}>Claim</MenuButton>
                        Only the address that created the campaign can Claim the campaign. <br /> 
                        The creator can claim the raised funds at any time when they are 100% or above the initial funding target.
                        <p/>
                        <CancelButton disabled={true}>Cancel</CancelButton>
                        Cancel an active campaign, preventing new pledges.<br/>
                        Only the address that created the campaign can Cancel the campaign.
                      </StyledDescriptionCenter>
                    </StyledDescription>
                  )}
                </StyledDescriptionTabs>

                <StyledPledges>
                  <StyledBlack />
                  <StyledTitle>Pledges</StyledTitle>
                  {!campaignInfo && <StyledButtonSpinner />}
                  <StyledSpacer height={20} />

                  {campaignInfo.pledges.map((pledge, index) => (
                    <StyledDonation key={index}>
                      <StyledDonateName nameLength={pledge.name.length}>
                        {pledge.name}
                      </StyledDonateName>
                      <StyledDonateMessage><i>{pledge.message}</i></StyledDonateMessage>
                      <StyledDonateAmount>
                        <StyledPledgeLogo size={20}/>
                        {pledge.amount}
                      </StyledDonateAmount>
                      <StyledPledgeID>#{pledge.pledgeID}</StyledPledgeID>
                    </StyledDonation>
                  ))}
                </StyledPledges>

              </DescriptionAndLinksContainer>
            </CampaignDetailContainer>
          }

      {fetchError && 
      <CampaignNotFound>
        Details for campaign #{id} were not found on the server
      </CampaignNotFound>
      }

    </PageContainer>
  );
}

interface StyledSpacerProps {
  height: number;
}
interface StyledLogoProps {
  size: number;
}
interface LogoDivProps {
  logoUrl: string;
}
interface MenuButtonProps {
  isactive?: boolean;
  width: number;
}
interface CancelButtonProps {
  isactive?: boolean;
}
interface StyledNFTProps {
  isSelected: boolean;
}
interface StyledNavBarEntryProps {
  isSelected: boolean;
}
interface StyledDonateName {
  nameLength: number;
}
interface StyledEndProps {
  pledgedAmount: number;
}

const sheenAnimation = keyframes`
0%, 25% { // Sheen visible and starts moving (0% to 25% of the animation duration)
  left: -30%;
}
50% { // Sheen finishes moving (at 50% of the animation duration)
  left: 100%;
}
50.1%, 100% { // Sheen is not visible or stationary for the rest of the duration
  left: -30%;
}
`;
const fillAnimation = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 660px;
  width: 100%;
  background: #000;
  border: 0px solid yellow;
`;
const StyledBreakline = styled.hr`
  position: relative;
  display: flex;
  border: none;
  height: 2px;
  background-color: #0AC18E;
  width: 95%;
  margin: 10px auto;
  top: 7px;
`;
const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 816px;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;

`;
const StyledBlack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.7;
  background: #000;
  border-radius: 10px;
`;
const StyledEnd = styled.div<StyledEndProps>`
  position: relative;
  height: 25px;
  display: flex;
  border: 0px solid yellow;
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.pledgedAmount > 0 ? '#0AC18E' : '#ea1313'};
  padding-left: 5px;
`;
const StyledEndBlock = styled.div`
  position: relative;
  height: 25px;
  display: flex;
  border: 0px solid yellow;
  font-weight: 400;
  font-size: 14px;
  color: #F8F8F8;
  padding-left: 5px;
`;
const CampaignNotFound = styled.div`
  position: relative;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 50px;
  width: 600px;
  color: #F8F8F8;
  background: #000;
  opacity: 0.8;
  border: 1px solid #0AC18E;
  @media (max-width: 670px) {
    width: 360px;
    height: 70px;
  }
`;
const CampaignDetailContainer = styled.div`
  position: relative;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 650px;
  max-width: 1050px;
  min-width: 1000px;
  width: 80%;
  border: 0px solid red;
  @media (max-width: 1000px) {
    width: 650px;
    min-width: 650px;
  }
  @media (max-width: 670px) {
    width: 100%;
    min-width: 300px;
  }
`;
const StyledNavBar = styled.div`
  position: relative;
  right: 110px;
  top: 0px;
  color: #F8F8F8;
  display: flex;
  flex-direction: row;
  border: 0px solid red;
  width: 700px;
  height: 30px;
  margin-top: 5px;
  justify-content: left;
  z-index: 3;
  @media (max-width: 1000px) {
    width: 650px;
    right: 0px;
  }
  @media (max-width: 670px) {
    width: 92%;

  }
`;
const StyledNavBarEntry = styled.div<StyledNavBarEntryProps>`
  border: 2px solid #0AC18E;
  border-radius: 15px 15px 0px 0px;
  border-bottom: ${props => props.isSelected ? '0px' : '2px solid #0AC18E;'};
  background: ${props => props.isSelected ? '#000' : '#000'};
  width: 33%;
  height: 30px;
  text-align: center;
  justify-content: center;
  z-index: 3;
  opacity: ${props => props.isSelected ? '1.0' : '0.5'};
  &:hover {
    opacity: ${props => props.isSelected ? '1.0' : '0.8'};
  }
`;
const StyledSpacer = styled.div<StyledSpacerProps>`
  position: relative;
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  border: 0px solid orange;
`;
const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 0px solid orange;
  @media (max-width: 670px) {
    width: 100%;
    justify-content: center;
  }
`;
const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0px solid yellow;
`;
const StyledRefundButtons = styled.div`
  position: absolute;
  left: 0px;
  top: 25px;
  width: 110px;
  height: 90px;
  display: flex;
  flex-direction: column;
  border: 0px solid orange;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    width: 150px;
  }
  @media (max-width: 670px) {
    width: 120px;
    min-width: 120px;
  }
`;
const BannerRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0px solid yellow;
  margin-left: 20px;
  @media (max-width: 670px) {
    margin-left: 5px;
  }
`;
const BannerImage = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-size: cover;
  width: 750px;
  height: 180px;
  background-color: #ddd; // Replace with your banner image
  border: 1px solid #0AC18E;
  box-shadow: 7px 7px 5px rgba(0,0,0,0.4);
  @media (max-width: 1000px) {
    width: 500px;
    height: 120px;
  }
  @media (max-width: 670px) {
    width: 90%;
    margin-bottom: 10px;
  }
`;
const UserContainer = styled.div`
  align-items: center;
  width: 100%;
  border: 0px solid white;
  text-align: center;
  @media (max-width: 670px) {
    position: absolute;
    right: 60px;
    top: -10px;
  }
`;
const LogoImage = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-size: cover;
  height: 150px; 
  width: 150px; 
  border: 0px solid #0AC18E;
  @media (max-width: 1000px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 670px) {
    width: 70px;
    height: 70px;
  }
`;
const UserName = styled.div`
  color: #F8F8F8;
  height: 40px;
  width: 150px;
  font-size: 1.0em;
  font-weight: 600;
  border: 0px solid red;
  overflow: hidden;
  @media (max-width: 1000px) {
    font-size: 0.8em
  }
  @media (max-width: 670px) {
    position: absolute;
    width: 200px;
    height: 15px;
    top: -7px;
    right: -10px;
    font-size: 0.7em;
    text-align: right;
  }
`;
const ProgressBar = styled.div`
  position: relative;
  display: flex;
  width: 920px;
  background-color: #84b6a8;
  justify-content: center;
  height: 35px;
  border: 2px solid #0AC18E;
  border-top: 0px;
  border-radius: 0px 0px 15px 15px;
  overflow: hidden;
  z-index: 2;
  flex-direction: row;
  color: #F8F8F8;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  @media (max-width: 1000px) {
    width: 650px;
  }
  @media (max-width: 670px) {
    width: 90%;
  }
`;
const StyledPledgeBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flext-start;
  position: relative;
  gap: 10px;
  width: 100%;
  max-width: 920px;
  border: 0px solid red;
  opacity: 0.9;
  margin-bottom: 10px;
  margin-top: 10px;
  justify-content: space-between;
  @media (max-width: 1000px) {
    justify-content: center;
  }
  @media (max-width: 670px) {
    width: 90%;
    min-width: 300px;
  }
`;
const DescriptionAndLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: 0px;
  top: 0px;
  gap: 5px;
  width: 925px;
  justify-content: space-between;
  border: 0px solid white;
  opacity: 0.9;
  min-height: 550px;
  overflow: visible;
  margin-bottom: 50px;
  @media (max-width: 1000px) {
    width: 650px;
  }
  @media (max-width: 670px) {
    width: 92%;
  }
`;
const StyledCampaignTitle = styled.div`
  position: relative;
  left: 0px;
  width: 100%;
  color: #F8F8F8;
  border-bottom: 1px solid #0AC18E;
  border-radius: 8px 8px 0px 0px;
  background: #0AC18E;
  height: 40px;
  z-index: 3;
  align-text: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
`;
const StyledTitleDiv = styled.div`
  position: relative;
  top: 0px;
  width: 920px;
  color: #F8F8F8;
  border: 2px solid #0AC18E;
  border-radius: 8px 8px 0px 0px;
  background: #000;
  height: 28px;
  z-index: 3;
  align-text: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  @media (max-width: 1000px) {
    width: 650px;
  }
  @media (max-width: 670px) {
    width: 90%;
  }
`;
const StyledDescription = styled.div`
  position: relative;
  padding: 10px;
  flex: 3;
  color: white;
  border: 2px solid #0AC18E;
  border-top: 0px;
  z-index: 2;
  background: #000;
  width: 100%;
  overflow-wrap: break-word;
  iframe {
    width: 100%;
    height: 400px;
    max-width: 800px;
    border: none;
  }
  img {
    max-width: 100%;
    max-height: 800px;
  }
  a {
    color: #0AC18E;
    &:hover {
      color: #09af81;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 670px) {
    width: 400px;
  }
`;
const StyledPledges = styled.div`
  position: relative;
  width: 210px;
  max-width: 210px;
  min-height: 300px;
  height: 560px;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #F8F8F8;
  background: #000;
  border: 2px solid #0AC18E;
  border-radius: 10px 10px 0px 0px;
  justify-content: flex-start;
  gap: 5px;
  overflow: visible;
  overflow-x: hidden;
  overflow-y: auto;
  a {
    color: #0AC18E;
    &:hover {
      color: #09af81;
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
    left: 1px;
  }
  @media (max-width: 670px) {
    width: 400px;
  }
`;
//Progress Bar
const ProgressBarText = styled.div`
  position: absolute;
  top: -2px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border: 0px solid red;
  padding-right: 5px;
  overflow: hidden;
  z-index: 3;
`;
const ProgressBarSubText = styled.div`
  position: absolute;
  top: 15px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border: 0px solid red;
  padding-right: 5px;
  overflow: hidden;
  z-index: 3;
`;
const Progress = styled.div<{ progress: number }>`
  display: flex;
  position: absolute;
  left: 0px;
  background-image: linear-gradient(to top, #024d38, #51e0b8); // Gradient for glass effect
  width: ${props => props.progress}%;
  height: 35px;
  overflow: hidden;
  z-index: 2;
  justify-content: left;
  align-items: left;
  animation: ${fillAnimation} 2s ease-out; // Apply the filling animation
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3), // Inner shadow for depth
              inset 0 2px 2px rgba(0,0,0,0.2); // More depth

  transform-origin: left;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    animation: ${sheenAnimation} 3.5s ease-in-out infinite;
  }
`;
const MenuButton = styled.button<MenuButtonProps>`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
  color: #F8F8F8;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: ${props => props.width}px;
  min-width: 50px;
  height: 32px;
  top: 7px;
  margin-bottom: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  &:hover {
    background-color: #0cd9a0;
    border: 2px solid #fff;
  }
  @media (max-width: 1000px) {
    width: 125px;
  }
  &:disabled {
    background: #aaa;
    cursor: not-allowed;
    border: 2px solid #aaa;
  }
`
const CancelButton = styled.button<CancelButtonProps>`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#ff0000' : '#aa0000')};
  color: #F8F8F8;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: 150px;
  min-width: 50px;
  height: 32px;
  top: 7px;
  margin-bottom: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  &:hover {
    background-color: #ff0000;
    border: 2px solid #fff;
  }
  @media (max-width: 1000px) {
    width: 125px;
  }
  &:disabled {
    background: #aaa;
    cursor: not-allowed;
    border: 2px solid #aaa;
  }
`
const StyledPledgeAmount = styled.div`
  position: relative;
  top: 5px;
  left: 5px;
  height: 20px;
  display: flex;
  width: 110px;
  border: 0px solid red;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  text-decoration: none;
  color: #F8F8F8;
  input { 
    width: 100%;
    border: inherit;
    font-size: inherit;
    color: inherit;
  }
`;
const StyledPledgeInput = styled.input`
  width: 140px;
  height: 20px;
  border: 0px solid red;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: #F8F8F8;
  background-color: white;
  color: black;
  border-radius: 5px;
  padding: 4px;
  margin-left: 7px;
  margin-top: 5px;
`;
const StyledPledgeLogo = styled.div<StyledLogoProps>`
  position: relative;
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  display: flex;
  border: 0px solid red;
`;
//Styled NFT UTXO
const StyledNFT = styled.div<StyledNFTProps>`
  position: relative;
  background-color: ${(props) => (props.isSelected ? '#0AC18E' : 'transparent')};
  color: white;
  padding: 0px;
  display: flex;
  flex-direction: row;
  width: 98%;
  height: 30px;
  margin-top: 5px;
  border: 2px solid  ${(props) => (props.isSelected ? '#fff' : '#0AC18E')};
  border-radius: 0px;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 5px rgba(255,255,255,0.3);
  &:hover {
    background-color: #0AC18E;
    border: 2px solid #fff;
  }
`;
const StyledNFTTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 0px solid yellow;
  height: 20px;
  padding-left: 5px;
  padding: 0px;
  text-decoration: none;
  color: #F8F8F8;
`;
const StyledNFTInfo = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  padding: 0px;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #000;
  color: #F8F8F8;
  border: 0px solid red;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;
const StyledSatsInfo = styled.div`
  position: relative;
  width: 100%;
  padding: 0px;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #000;
  color: #F8F8F8;
  opacity: 0.7;
  border: 0px solid red;
  z-index: 1;
  display: flex;
  flex-direction: columns;
`;
const StyledNFTText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-left: 5px;
  text-align: center;
  color: white;
  border: 0px solid red;
  font-size: 12px;
  font-weight: 600;
  overflow-wrap: break-word;
  word-wrap: break-word;
  flex-shrink: 1;
  white-space: normal;
`;
const StyledNFTTextRow = styled.div`
  display: flex;
  flex-direction: row; 
  align-items: center;
`;
const StyledSats = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  margin-left: 10px;
  left: 100px;
  color: white;
  border: 0px solid red;
  font-size: 14px;
  font-weight: 600;
`;
const StyledNFTContainer = styled.div`
  position: absolute;
  top: 22px;
  right: 0px;
  display: flex;
  width: 235px;
  height: 92px;
  flex: 1 1 auto;
  flex-wrap: wrap;
  border-radius: 10px;
  border: 0px solid red;
  border-radius: 0px;
  overflow-x: auto;
  overflow-y: auto;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: 1000px) {
    width: 70%;
    justify-content: center;
  }
  @media (max-width: 670px) {
    width: 60%;
    min-width: 60%;
  }
`;
const StyledRefreshButton = styled.button<MenuButtonProps>`
position: relative;
top: -10px;
background-color: #000;
color: #F8F8F8;
border: 1px solid #fff;
border-radius: 10px;
font-size: 14px;
cursor: pointer;
z-index: 10;
width: 75px;
height: 25px;
justify-content: center;
text-align: center;
align-items: center;
&:hover {
  background-color: #0AC18E;
}
&:active {
  background-color: red;
}
`
const StyledFailButton = styled.button<MenuButtonProps>`
position: absolute;
background-color: #bb0000;
color: #F8F8F8;
border: 1px solid #fff;
font-size: 14px;
font-weight: 600;
cursor: pointer;
z-index: 10;
width: 75px;
height: 25px;
top: 4px;
right: 30px;
justify-content: center;
text-align: center;
align-items: center;
&:hover {
  background-color: #990000;
}
`
const StyledCampaignID = styled.div`
  position: relative;
  height: 25px;
  display: flex;
  border: 0px solid blue;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: #F8F8F8;
  padding-left: 5px;
`;
const Input = styled.input`
  margin: 10px;
  padding: 5px;
`;
const StyledDescriptionCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0px solid red;
`;
const StyledSpinner = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(${ImageSpinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 150px;
  width: 150px;
  display: flex;
  border: 1px solid #0AC18E;;
  z-index: 100;
  @media (max-width: 1000px) {
    width: 100px;
    height: 100px;
    right: 50px;
  }
  @media (max-width: 670px) {
    width: 70px;
    height: 70px;
    left: 0px;
  }
`;
const StyledButtonSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
  display: flex;
  border: 0px solid red;
`;
const StyledRowDetails = styled.div`
  position: relative;
  display: flex;
  width: 260px;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  border: 2px solid #0AC18E;
  border-radius: 10px;
  max-height: 120px;
`;
const StyledRowPledge = styled.div`
  position: relative;
  display: flex;
  width: 200px;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  border: 2px solid #0AC18E;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  max-height: 120px;
  @media (max-width: 670px) {
    height: 120px;
    max-height: 120px;
  }
`;
const StyledRowNFTs = styled.div`
  position: relative;
  display: flex;
  width: 300px;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  border: 2px solid #0AC18E;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 120px;
`;
const StyledTitle = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0px;
  background: #0AC18E;
  border-radius: 8px 8px 0px 0px;
  color: #F8F8F8;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #0AC18E;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
`;
const StyledDonation = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  color: #F8F8F8;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #0AC18E;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
`;
const StyledDonateName = styled.div<StyledDonateName>`
  width: 100%;
  font-size: ${(props) => (props.nameLength > 15 ? '12px' : '16px')};
  font-weight: ${(props) => (props.nameLength > 15 ? '700' : '600')};
  border: 0px solid blue;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  word-wrap: break-word;
`;
const StyledDonateAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #F8F8F8;
  font-size: 16px;
  font-weight: 600;
  border: 0px solid yellow;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  justify-content: center;
  gap: 5px;
  padding: 3px;
`;
const StyledPledgeID = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 30px;
  height: 20px;
  color: #F8F8F8;
  background: #0AC18E;
  font-size: 14px;
  padding-left: 4px;
  font-weight: 600;
  border: 0px solid yellow;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-text: center;
`;
const StyledDonateMessage = styled.div`
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  border: 0px solid red;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  word-wrap: break-word;
  padding-left: 3px;
  padding-right: 5px;
`;
const StyledDescriptionTabs = styled.div`
  display: flex;
  position: relative;
  width: 700px;
  left: 3px;
  border: 0px solid orange;
  @media (max-width: 1000px) {
    width: 650px;
    left: 0px;
  }
  @media (max-width: 670px) {
    width: 100%;
  }
`;
export default CampaignDetail;