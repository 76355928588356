import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/SideBar'; 
import CasualLogo from '../../assets/img/casualLogo.png';

interface Category {
  name: string;
  questions: { question: string; answer: string; answerVisible: boolean }[];
}

interface StyledAnswerProps {
  visible: boolean;
}


const categories: Category[] = [
  {
    name: 'FundMe',
    questions: [
      {
        question: 'What is FundMe?',
        answer: 'A website for interacting with decentralized crowdfunding contracts on the BitcoinCash blockchain.',        
        answerVisible: false,
      },
      {
        question: 'Does it cost anything?',
        answer: 'Using FundMe.Cash to create a campaign costs 0.01 BCH. <br /> \n\
        Claiming a successful campaign with FundMe.Cash costs 1.5% of the raised funds. <p/> \n\
        You can also use another website that interacts with the CashStarter contract which may have lower fees, or you can create your own interactions and remove the fees entirely.',
        answerVisible: false,
      },
      {
        question: 'Why can the fees be different?',
        answer: 'Websites and apps that let users interact with the CashStarter contract, like FundMe.Cash, have the option of building the interactions so that the user pays a fee up to a maximum of 0.01 BCH to an address the website specifies. <p/> \n\
        This will hopefully incentivize multiple independent websites to interact with the CashStarter contract so that nobody is relying on a single website.',
        answerVisible: false,
      },
      {
        question: 'I created a campaign outside of FundMe and want it listed on FundMe',
        answer: 'It will still cost the 0.01 BCH listing fee. Contact us with your campaign number, payout address you used, and your campaign data (logo, banner, short description, full description). \n\
        We will ask you to sign a message with your payout address to prove ownership of it, we can walk you through it.',
        answerVisible: false,
      },
      {
        question: 'Are there any restrictions?',
        answer: 'Yes, check our <a href="https://fundme.cash/Rules">Rules page for further details.',
        answerVisible: false,
      },
      {
        question: 'How does Pledging work?',
        answer: 'Connect your walletconnect-compatible wallet, enter the number of BCH you want to pledge to a campaign, click Pledge, then approve the transaction in your wallet. <p/> \n\
        You will receive a PledgeNFT which entitles you to perform a refund at any time if the campaign hasn\'t already been claimed by the creator. <p/> \n\
        <b>Do not lose the PledgeNFT!</b> It is impossible to retrieve the pledged BCH without it. <p/> \n\
        There is also a maximum of 1,407,374 BCH per pledge.',
        answerVisible: false,
      },
      {
        question: 'How do Refunds work?',
        answer: 'Refunds can be performed on any campaign that has not already been Claimed by the creator. <p/> \n\
        Connect your wallet and go to the campaigns details page. "Refresh" the PledgeNFTs and select yours, then click Refund and approve the transaction in your wallet. <p/> \n\
        This will return the BCH you gave to the campaign and burn (destroy) your PledgeNFT.',
        answerVisible: false,
      },
      {
        question: 'How does Claiming work?',
        answer: 'Once a campaign has met or exceeded the campaigns BCH goal the creator (and only the creator) can Claim the campaign. <p/> \n\
        Connect the wallet you used to create the campaign then go to the campaigns details page. Select the "Claim" tab, then click Claim and approve the transaction in your wallet. <p/> \n\
        The raised BCH (minus a 1.5% fee) will be sent to your wallet. Congrats!',
        answerVisible: false,
      },
      {
        question: 'How does Stopping work?',
        answer: 'Once the blockchain passes the campaigns configured EndBlock then a "Stop" button will appear on the campaigns details page which anyone can click. Doing so will cause the campaign to \n\
        enter a "Stop" state where new pledges cannot be created for the campaign. Once the last pledger has refunded then the campaign will disappear.<p/> \n\
        A Stopped campaign that still meets its BCH goal can still be claimed by the creator. <p/> \n\
        Pledgers can still refund their BCH from a Stopped campaign that did not raise enough BCH to be successful. <p/> \n\
        Pledgers can still refund their BCH from a Stopped campaign that <b>did</b> raise enough BCH, as long as they do it before the creator claims it.',
        answerVisible: false,
      },
      {
        question: 'How does Canceling work?',
        answer: 'If you created a campaign and don\'t want to continue it you can Cancel the campaign. This prevents new pledges from being applied to it. It puts the campaign into the same state as if it \n\
        had been "Stopped". Cancelling just allows the creator to enter that state early. <p/> \n\
        Once a campaign is cancelled those who already pledged can still refund. Once the last pledger has refunded then the campaign UTXO will disappear.',
        answerVisible: false,
      },
      {
        question: 'Who created FundMe?',
        answer: 'Sayoshi Nakamario',
        answerVisible: false,
      },
    ],
  },
  {
    name: 'Campaigns',
    questions: [
      {
        question: 'What are the campaign limits?',
        answer: 'Maximum BCH you can request: 1,407,374 BCH <br/> \n\
        Latest campaign expiration: Block 2,147,483,647. (roughly Year 2326 😊)',
        answerVisible: false,
      },
      {
        question: 'Is there a limit on the number of pledges?',
        answer: 'Yes, a maximum of 2,147,483,647 pledges per campaign.',
        answerVisible: false,
      },
      {
        question: 'How large can a pledge be?',
        answer: 'Maximum of 1,407,374 BCH per pledge.',
        answerVisible: false,
      },
      {
        question: 'How small can a pledge be?',
        answer: 'Minimum of 0.0001 BCH per pledge, which requires an additional 0.00002 to cover the pledgeNFT (which you receive) and the miner fee.',
        answerVisible: false,
      },
      {
        question: 'Can I do a campaign for anything?',
        answer: 'Yes! But not necessarily here! <p/> \n\
        Anyone can use the CashStarter contract since it\'s hosted on the blockchain, but FundMe.Cash will filter out \n\
        and not display what we consider to be illegal or objectionable campaigns. Other websites or apps may operate differently.<p/> \n\
        Check our <a href="https://fundme.cash/Rules">Rules page for further details.',
        answerVisible: false,
      },
      {
        question: 'What are the Campaign details?',
        answer: '<b>Campaign Ends (Block #):</b> Once this block number is reached anyone can "Fail" the campaign which will prevent any new pledges. Think of it as your deadline or end-date. <p/> \n\
        <b>Fundraising Amount:</b> How much BCH you want to raise. <p/> \n\
        <b>Campaign Name:</b> The name of your campaign <p/> \n\
        <b>Campaign Owner:</b> Your name, or the group running the campaign <p/> \n\
        <b>Banner:</b> A maximum 500x120 pixel image which will show on FundMe.Cash\'s homepage and your campaigns detailed page. <p/> \n\
        <b>Logo:</b> A maximum 150x150 pixel image of you, your logo, avatar, groups logo, etc. <p/> \n\
        <b>Short Description:</b> A quick sales pitch for your campaign that shows up on FundMe.Cash\'s homepage. <p/> \n\
        <b>Full Description:</b> Your full campaign writeup. Text formatting, links, images, and embedded YouTube videos are supported.',
        answerVisible: false,
      },
      {
        question: 'Where are the Campaign details hosted?',
        answer: 'These details are stored directly in the contract on the blockchain: \n\
        <ul><li>Amount of BCH attempting to raise</li>\n\
        <li>Creators pubkeyhash (address where the raised BCH will be sent)</li>\n\
        <li>Block # the campaign can Stop at</li>\n\
        <li>Unique campaign ID</li></ul> <p/>\n\
        If you create the campaign on FundMe.Cash then our server hosts your descriptions, images, and updates. A distributed filestore to host this campaign data is a future goal.',
        answerVisible: false,
      },
    ],
  },
  {
    name: 'CashStarter',
    questions: [
      {
        question: 'What is CashStarter?',
        answer: 'The smart contract running on the BitcoinCash blockchain. This contract is what actually handles the BCH and manages the campaign.<p />\n\
        You are not required to use FundMe.Cash to interact with the CashStarter contract.',        
        answerVisible: false,
      },
      {
        question: 'So I could earn BCH by hosting a CashStarter website?',
        answer: 'Yes',
        answerVisible: false,
      },
      {
        question: 'How many campaigns can CashStarter handle?',
        answer: '549,755,813,887 campaigns. <br/> After that a new contract would need to be created.',
        answerVisible: false,
      },
      {
        question: 'Does anyone have control of the CashStarter contract?',
        answer: 'No',        
        answerVisible: false,
      },
      {
        question: 'Can CashStarter get new features developed?',
        answer: 'Yes, but new features will require building new contracts that include those features. However, there\'s nothing stopping websites from interacting \n\
        with multiple contracts at the same time. <p/> \n\
        For example, when launching a campaign you could have the choice of which <i>style</i> of campaign you want to create, with each style usinga different CashStarter contract in the background.',        
        answerVisible: false,
      },
      {
        question: 'How was CashStarter made?',
        answer: 'With massive help from the BCH community, from documentation and tooling to people putting up with my incessant newbie questions 😁 <p/> \n\
        I\'m sure I\'ll miss people, but special thanks to: <br/> \n\
        <ul><li><b>Mathieu Geukens:</b> for working on <a href="https://cashscript.org/">CashScript</a>, <a href="https://cashonize.com/">Cashonize</a>, <a href="https://playground.cashscript.org/">CashScript Playground</a>, writing example contracts, and helping debug my errors. (a minority of them weren\'t even my fault!)</li> \n\
        <li><b>Rosco Kalis:</b> also for working on CashScript and helping debug errors.</li> \n\
        <li><b>Jason Dreyzehner:</b> for driving the CashTokens specification and working on <a href="https://libauth.org/">LibAuth</a>.</li> \n\
        <li><b>mainnet_pat:</b> for working on and documenting the <a href="https://github.com/mainnet-pat/wc2-bch-bcr">WallectConnect integration</a> for BCH and helping debug errors.</li> \n\
        <li><b>Sahid:</b> for helping debug errors, showing me his own implementation of fundraising contracts on <a href="https://bchouse.fly.dev/">BCHouse</a>, and even giving me an initial \'how-to\' teaching session on creating transactions and using WalletConnect.</li> \n\
        <li><b>George Donnelly:</b> for creating the <a href="https://www.bitcoincashsite.com/blog/token-pioneers-cashtokens-tutorial-1/">Token Pioneers tutorials</a> which I found very helpful.</li> \n\
        <li><b>bitcoincashautist:</b> for writing example contracts and helping debug errors.\n\
        <li><b><a href="https://paytaca.com/">Paytaca:</a></b> for helping me debug, and even building a custom version of their wallet for me to test with.\n\
        <li><b><a href="https://zapit.io/">Zapit</a></b>: also for helping me debug.\n\
        <li><b>Salem Kode</b>: for running <a href="https://explorer.salemkode.com/">explorer.salemkode.com</a> which I used extensively.\n\
        <li>And many other people who helped by chatting with me about issues 😅</li></ul> \n\
        ',        
        answerVisible: false,
      },
    ],
  },
  {
    name: 'Problems',
    questions: [
      {
        question: 'My campaign data failed to upload',
        answer: 'Contact us with your campaign number, payout address you used, and your campaign data (logo, banner, short description, full description) \n\
        We will ask you to sign a message with your payout address to prove ownership of it, we can walk you through it.',
        answerVisible: false,
      },
      {
        question: 'I want to edit my campaign description',
        answer: 'We don\'t alter campaign descriptions as a rule, but we can make exceptions for minor changes like important typos.',
        answerVisible: false,
      },
      {
        question: 'I want to create a campaign outside of the current campaign limits',
        answer: 'This will require launching a new CashStarter contract. Contact us to determine if it\'s possible to do!',
        answerVisible: false,
      },
    ],
  },
  // Add more categories...
];

const FAQ = () => {
  const defaultSelection = categories[0];
  const [selectedCategory, setSelectedCategory] = useState<null | Category>(defaultSelection);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
  };

  const toggleQuestion = (index: number) => {
    setSelectedCategory((prevCategory) => {
      if (!prevCategory) return prevCategory; 
  
      const newCategory: Category = {
        ...prevCategory, 
        questions: prevCategory.questions.map((q, idx) => {
          if (idx === index) {
            return { ...q, answerVisible: !q.answerVisible };
          }
          return q;
        }),
      };

      return newCategory;
    });
  };

  return (
    <FAQContainer>
     <StyledBackground />
     <Sidebar categories={categories} onCategoryClick={handleCategoryClick} />

      <FAQContent>
        {selectedCategory ? (
          <StyledUL>
            {selectedCategory.questions.map((faq, index) => (
              <li key={index}>
                <StyledTitle onClick={() => toggleQuestion(index)}>
                  {faq.question}
                </StyledTitle> 
                <StyledAnswer visible={faq.answerVisible}>
                  <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                </StyledAnswer>
              </li>
            ))}
          </StyledUL>
        ) : (
          <></>
        )}
      </FAQContent>
    </FAQContainer>
  );
};

const StyledUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  word-wrap: break-word;
`;
const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 816px;
  width: 1456px;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
  @media (max-width: 670px) {
    width: 1020px;
    height: 571px;
  }
`;
const StyledTitle = styled.button`
  display: flex;
  background-color: #000;
  color: white;
  font-size: 16px;
  border: 2px outset #0ac18e;
  border-radius: 0px; 
  padding: 5px 10px;  
  cursor: pointer;
  text-align: left; 
  width: 500px;   
  max-width: 1200px;
  
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin-bottom: 5px;
  &:hover {
    background-color: #004295;
    border: 2px outset #0ac18e;
  }
  @media (max-width: 800px) {
    font-size: 14px;
    width: 300px;
  }
`;
const StyledAnswer = styled.div<StyledAnswerProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  border: 2px dashed #0ac18e;
  background-color: #002017;
  color: white;
  font-size: 14px;
  margin-bottom: 5px; 
  border-radius: 0px;
  margin-left: 15px;
  width: 100%;
  max-width: 470px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: left;
  padding-left: 10px;
  line-height: 18px;
  font-size: 16px;
  a {
    color: #09b182;
    &:hover {
      color: #0ad69e;
    }
  }
  @media (max-width: 800px) {
    font-size: 14px;
    max-width: 270px;
  }
`;
const FAQContainer = styled.div`
display: flex;
flex-direction: column;
background-color: #000;
height: 100%;
min-height: 100vh;
width: 100%;
color: white;
border: 0px solid red;
align-items: center;
`;
const FAQContent = styled.div`
position: relative;
display: flex;
flex-direction: column;
padding: 20px;
background-color: transparent;
border: 0px solid red;
justify-content: center;
align-items: center;
@media (max-width: 800px) {
  left: -50px;
}
`;

export default FAQ;
