import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';
import './quill.snow.css';
import styled from 'styled-components';

interface RichTextEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, onChange }) => {      
    const [editorValue, setEditorValue] = useState(value);
    const reactQuillRef = useRef<ReactQuill | null>(null);

    const handleChange = (content: string) => {
      setEditorValue(content);
      onChange(content);
    };

    useEffect(() => {
        const editor = document.querySelector('.ql-editor');
        if (editor && editor.innerHTML === '<p><br></p>') {
          editor.classList.add('ql-blank');
        } else if (editor) {
          editor.classList.remove('ql-blank');
        }
      }, [editorValue]);

  return (
    <Container>
      <ReactQuill
        ref={reactQuillRef}
        className="custom-quill"
        placeholder="Enter your full campaign description here (Max 25MB)"
        theme="snow"
        //value={value}
        //onChange={onChange}
        value={editorValue}
        //defaultValue={editorValue}
        onChange={handleChange}
        modules={{
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean']
          ],
          }}
        formats={[
          'header', 'font',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'color', 'background',
          'list', 'bullet', 'align',
          'link', 'image', 'video'
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  z-index: 1;
  width: 100%;
  margin-top: 25px;
`;

export default RichTextEditor;