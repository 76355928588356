import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LogoTelegram from '../../assets/img/telegram.png';

function Footer() {
    // Use state to track the current page if needed
    const [currentPage, setCurrentPage] = useState('home');
  
    // Function to toggle the current page if needed
    const togglePage = (page: string) => {
      setCurrentPage(page);
    };
  
    return (
      <StyledFooter>
        <StyledSocialIcons> 
          <a href="https://t.me/FundMe_Cash"><StyledSocialIcon logo={LogoTelegram} /></a>
        </StyledSocialIcons>
      </StyledFooter>
    );
  }

  interface SocialIconProps {
    logo: string;
  }

  const StyledFooter = styled.div`
  height: 100px;
  width: 100%;
  background-color: black;
  color: white;
  position: relative;
  display: flex;
  border-top: 1px solid #0AC18E;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 900px) {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
`;

const ContactContainer = styled.div`
  color: white;
  font-size: 18px;
  text-align: center;
  height: 100%;
  max-width: 100%; /* Add max-width to prevent overlapping */

  a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 900px) {
    margin-bottom: 15px;
  }
`;
const StyledSocialIcons = styled.div`
  position: absolute;
  top: 10px;
  right: 50px;
  display: flex;
  border: 0px solid red;
  gap: 10px;
  color: white;
  font-size: 20px;
  text-decoration: none;
  overflow-x: hidden;
  align-items: flex-end;
`;
const StyledSocialIcon = styled.div<SocialIconProps>`
  background-image: url(${props => props.logo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 45px;
  width: 40px;
  height: 40px;
`;
const AddressBox = styled.div`
  position: absolute;
  margin-top: 20px;
  height: 100%;
  left: 20px;
  color: white;
  font-size: 14px;
  text-align: center;
  max-width: 100%; /* Add max-width to prevent overlapping */

  p {
    margin: 0;
  }

  a {
    color: white;
    text-decoration: none;
    margin-left: 10px;
  }

  @media (max-width: 900px) {
    position: static;
    margin-top: 0;
  }
`;
const StyledAddressImage = styled.div`
  
display: fixed;
  gap: 10px;
  img {
    max-width: 60px;
    height: auto;
  }

`;
const StyledLink = styled(Link)`
  position: absolute;
  top: 10px; /* Adjust top positioning as needed */
  right: 10px; /* Adjust right positioning as needed */
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #0AC18E; /* Green border color */
  color: #fff; /* White text color */
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s; /* Transition for glow effect */
  @media (max-width: 900px) {
    position: static;
    margin-top: 0;
  }
  &:hover {
    background-color: #0AC18E; /* Green background color on hover */
    border: 2px solid #fff;
    box-shadow: 0 0 10px rgba(0, 128, 0, 0.5); /* Glow effect on hover */
  }


`;

export default Footer;